import * as React from 'react';
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NavDrawer from './NavDrawer'
import useMobile from "../hooks/useMobile";
import NavMenu from "./NavMenu";

const NavBar = () => {

  const {isMobile} = useMobile();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: "#BF1A06" }}>
        <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
          <img src={require('../assets/nav_logo_sm.png')} alt='nav logo' style={{ maxHeight: '48px' }} />
          {isMobile ? <NavDrawer/> : <NavMenu/>}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavBar;