import * as React from 'react';
import NavBar from './components/NavBar'
import AppBg from "./components/AppBg";
import Services from "./components/Services";
import About from "./components/About";
import Footer from "./components/Footer";
import EmailForm from "./components/EmailForm";


const App = () => {

  return (
    <>
      <NavBar />
      <AppBg />
      <About />
      <Services />
      <EmailForm />
      <Footer />
    </>
  );
}

export default App;
