import * as React from 'react';
import Box from "@mui/material/Box";
import {Card, CardContent, CardMedia, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

const Services = () => {

  const CardMediaStyle = {
    maxHeight: '325px',
  }

  const CardStyle = {
    color: '#e0e0e0',
    backgroundColor: '#3A0E05',
    height: '100%',
  }

  return (
    <Box id='services' maxWidth='1280px'
      sx={{
        margin: 'auto',
        marginTop: '70px',
        marginBottom: '5px',
        scrollMarginTop: '12vh',
        '@media screen and (max-width: 770px)': {
          scrollMarginTop: '56px',
        },
      }}>
      <Grid container spacing={2} sx={{
        paddingLeft: '10px',
        paddingRight: '10px',
      }}>
        <Grid item xs={12} sm={4}>
          <Card style={CardStyle}>
            <CardMedia
              component="img"
              image={require("../assets/shingling_service.jpg")}
              alt="shingling"
              style={CardMediaStyle}
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                Roof Installation
              </Typography>
              <Typography variant="body1" textAlign='center'>
                We offer professional roof installation services to ensure that your home or business is protected from
                the elements. Our expert roofers have years of experience installing a variety of roofing materials,
                including asphalt shingles, cedar roofing, and fiberglass roof systems. We take pride in our workmanship and
                strive to deliver the highest quality installations. Contact us to schedule a consultation and get a
                free estimate for your roof installation project.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={CardStyle}>
            <CardMedia
              component="img"
              image={require("../assets/repair_service.jpg")}
              alt="shingling"
              style={CardMediaStyle}
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                Repairs
              </Typography>
              <Typography variant="body1" textAlign='center'>
                Roof repairs can be essential to maintaining the integrity and lifespan of your roof. We
                offer professional roof repair services to fix any damages or issues that may be affecting your roof.
                Whether it's a small leak or significant storm damage, our team of expert roofers has the skills and
                experience to get your roof back in top condition.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={CardStyle}>
            <CardMedia
              component="img"
              image={require("../assets/inspection_service.jpg")}
              alt="shingling"
              style={CardMediaStyle}
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                Roof Inspection
              </Typography>
              <Typography variant="body1" textAlign='center'>
                Regular roof inspections are an important part of maintaining the health and longevity of your roof.
                We offer professional roof inspections to help identify any potential issues or problems that may be
                affecting your roof. Our team of expert roofers will thoroughly assess the condition of your roof and
                provide a detailed report of their findings. We recommend scheduling an inspection at least once a year,
                especially after severe weather events.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Services;