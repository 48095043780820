import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Grid} from "@mui/material";

const About = () => {

  return (
    <Box
      id='about'
      color='#e0e0e0'
      backgroundColor='#BF1A06'
      sx={{
        scrollMarginTop: '20vh',
        '@media screen and (max-width: 770px)': {
          scrollMarginTop: '56px',
        },
      }}
    >
      <Grid container
            alignItems='center'
            justifyContent='center'
            maxWidth='1920px'
            margin='auto'
      >
        <Grid item sm={12} md={6} textAlign='center' padding='40px'>
          <Typography variant="h5" sx={{ fontSize: '2rem'}}>
            About Us
          </Typography>
          <Typography variant='body 1'>
            Providing top-quality roofing service to the island for over 25 years. We specialize in all aspects of roofing,
            from repairs and maintenance to complete roof replacements. We use only the highest-quality materials and
            adhere to the BC building code to ensure that your roof is durable, reliable, and long-lasting.
            <br /><br />
            We are dedicated to delivering the best possible results for every job we take on. Whether you need a new
            roof installed, an old roof repaired, or simply need some maintenance work done, we have the knowledge and
            expertise to get the job done right.
            <br /> <br />
            If you are in need of roofing services, we encourage you to give us a call. Our friendly and knowledgeable
            team will be happy to answer any questions you may have, and we will work with you to develop a plan that
            meets your needs and budget. We look forward to working with you!
            <br />
          </Typography>
          <Button
            variant='contained'
            href='#contact'
            sx={{
              padding: '14px',
              margin: '20px',
            }}
          >
            Contact Us
          </Button>
        </Grid>
        <Grid item sm={12} md={4} padding='24px'>
          <img
            src={require('../assets/rob_with_truck.jpg')}
            alt='Robert standing next to his truck'
            style={{
              maxWidth: '100%',
              borderRadius: '5px',
              boxShadow: '2px 2px 10px black'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default About;