import * as React from 'react';
import Box from "@mui/material/Box";

const imgStyle = {
  height: "100vh",
  margin: '0 auto',
  opacity: '0.6',
}

const AppBg = () => {

  return(
    <Box id='home' sx={{
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}>
      <img
        style={imgStyle}
        src={require('../assets/background_desktop.jpg')}
        alt='background'
      />
      <img
        style={{
          position: "absolute",
          width: '80%',
          maxWidth: '510px'
        }}
        src={require('../assets/main_logo.png')}
        alt='logo'
      />
    </Box>
  );
}

export default AppBg;