import * as React from 'react';
import Box from "@mui/material/Box";
import { useState } from 'react';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import HardwareIcon from '@mui/icons-material/Hardware';
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer} from "@mui/material";


const NavDrawer = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return(
    <>
      <IconButton size='large' edge='start' aria-label='menu' onClick={() => setOpenDrawer(true)}>
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        anchor='top'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
      >
        <Box p={2} textAlign='left' role='presentation'>
          <List>
            <ListItem disablePadding>
              <ListItemButton href='#'>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href='#about'>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary="About us" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href='#services'>
                <ListItemIcon>
                  <HardwareIcon />
                </ListItemIcon>
                <ListItemText primary="Services" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href='#contact'>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default NavDrawer;