import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Footer = () => {

  return (
    <>
      <footer>
        <Box
          textAlign='center'
          sx={{
            height: '70px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            color: '#e0e0e0',
            backgroundColor: '#070A0D'
          }}
        >
          <Typography>
            &copy; 2023 Roberto Maigler Roofing
          </Typography>
        </Box>
      </footer>
    </>
  );
}

export default Footer;