import * as React from 'react';
import Box from "@mui/material/Box";
import {Button} from "@mui/material";

const NavMenu = () => {
  return (
    <Box>
      <Button href='#' variant='text' size='large' sx={{  color: '#e0e0e0' }}>Home</Button>
      <Button href='#about' variant='text' size='large' sx={{  color: '#e0e0e0' }}>About us</Button>
      <Button href='#services' variant='text' size='large' sx={{  color: '#e0e0e0' }}>Services</Button>
      <Button href='#contact' variant='text' size='large' sx={{  color: '#e0e0e0' }}>Contact</Button>
    </Box>
  );
}

export default NavMenu;