import * as React from 'react';
import {Button, Card, CardContent, Grid, ListItem, ListItemText, styled, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from "@mui/material/Typography";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const EmailForm = () => {
  const form = useRef();

  const CssTextField = styled(TextField)({
    'textarea': {
      color: '#ADADAD',
    },
    'input': {
      color: '#ADADAD',
    },
    'label': {
      color: '#ADADAD',
    },
    '& label.Mui-focused': {
      color: '#3d3d3d',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3d3d3d',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#3d3d3d',
      },
      '&:hover fieldset': {
        borderColor: '#666666',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#666666',
      },
    },
  });

  const CssListItemText = styled(ListItemText)({
    'span': {
      fontSize: '1.5em',
    },
  });

  const CssListItem = styled(ListItem)({
    paddingLeft: '16px',
    paddingBottom: '0px',
    paddingTop: '0px',
    'div': {
      paddingLeft: '7px',
    },
    'p': {
      color: '#ADADAD',
    },
  });

  const ContactDiv = {
    color: '#ADADAD',
    textAlign: 'center',
    paddingTop: '20px',
    minWidth: '204px',
    margin: '0px',
  }

  const ContactCardStyle = {
    color: '#ADADAD',
    backgroundColor: '#0E141B',
    width: '100%',
  }

  // logic to handle emailjs form
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wrvvdq6', 'template_13bu89m', form.current, 'QnELUDJQvNqfPoB9z')
      .then((result) => {
        console.log(result.text);
        document.getElementById("email-form").reset();
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <Box id='contact' maxWidth='1280px' sx={{
      margin: 'auto',
      display: 'flex',
      '@media screen and (max-width: 770px)': {
        flexDirection: 'column',
      },
    }}>
      <Box style={ContactDiv}>
        <CssListItem>
          <LocationOnIcon />
          <CssListItemText primary="Duncan, BC" secondary="757 Castle St V9L 2J9" />
        </CssListItem>
        <CssListItem>
          <LocalPhoneIcon />
          <CssListItemText primary="Phone" secondary="(250) 709-7665" />
        </CssListItem>
        <CssListItem>
          <EmailIcon />
          <CssListItemText primary="Email" secondary='maigler@shaw.ca' />
        </CssListItem>
      </Box>
      <Card style={ContactCardStyle}>
        <CardContent sx={{paddingTop: '5px'}}>
          <Typography variant='h4' paddingTop='10px'>Contact us</Typography>
          <form id='email-form' ref={form} onSubmit={sendEmail}>
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <CssTextField label="Name" type="text" name="user_name" fullWidth required autoComplete="none" variant="outlined" />
              </Grid>
              <Grid xs={12} item>
                <CssTextField type="email" label="Email" name="user_email" fullWidth required autoComplete="none" variant="outlined" />
              </Grid>
              <Grid xs={12} item>
                <CssTextField label="Message" name='message' fullWidth required autoComplete="none" variant="outlined" multiline rows={5}/>
              </Grid>
              <Grid xs={12} item>
                <Button type="submit" variant="contained" sx={{backgroundColor: "#5378a2"}}>Submit</Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default EmailForm;