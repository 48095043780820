import {useEffect, useState} from "react";

const useMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 768;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);

    }
  }, []);

  return {isMobile};
}

export default useMobile;